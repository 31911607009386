'use client';

import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

export type SearchParamsType = {
  fbc?: string;
  fbclid?: string;
  gclid?: string;
  msclkid?: string;
  ttclid?: string;
  ad_id?: string;
  OutbrainClickId?: string;
  ScCid?: string;
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  cItemId?: string;
  msclkId?: string;
};

export const searchParamsAtom = atomWithStorage<SearchParamsType>(
  'searchParamsAtom',
  {} as SearchParamsType,
);

export const searchParamsValueAtom = atom(
  null,
  async (get, set, searchParams: SearchParamsType) => {
    if (get(searchParamsValueAtom)) return;

    const oldSearchParams = get(searchParamsAtom);
    const newSearchParams = { ...oldSearchParams, ...searchParams };

    set(searchParamsAtom, newSearchParams);
  },
);
