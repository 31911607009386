import { FC } from 'react';

import { Typography } from '../../Typography/Typography';
import { DropdownOptionProps } from './DropdownOption.types';

import styles from '../Styles/Option.module.css';

const DropdownOption: FC<DropdownOptionProps> = ({
  leftContent,
  label,
  rightContent,
  ...restProps
}) => {
  return (
    <div {...restProps} className={styles.contentContainer}>
      {leftContent && <span className={styles.leftContent}>{leftContent}</span>}
      <Typography Component="span" className={styles.optionContent} variant="subheading4">
        {label}
      </Typography>
      {rightContent && (
        <Typography Component="span" className={styles.rightContent} variant="caption2">
          {rightContent}
        </Typography>
      )}
    </div>
  );
};

export default DropdownOption;
