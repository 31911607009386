import styles from './LoadingBackground.module.css';

export const LoadingBackground = () => {
  return (
    <span data-test-id="statusDisplayLoading" className={styles.loadingBackgroundWrapper}>
      &nbsp;
      <span className={styles.loadingBackground} />
    </span>
  );
};
