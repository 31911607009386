import { FC } from 'react';
import cn from 'classnames';

import useCollapse from './Hooks/UseCollapse';
import { CollapseProps } from './Collapse.types';

import styles from './Styles/Default.module.css';

export const Collapse: FC<CollapseProps> = ({
  children,
  open = false,
  className,
  dataTestId,
  onTransitionEnd,
  showDropShadow,
  ...restProps
}) => {
  const { collapseContent, collapseStyles, handlerTransitionEnd } = useCollapse(
    open,
    showDropShadow,
  );
  const classNames = cn(styles.collapse, className);

  const handleTransitionEnd = () => {
    handlerTransitionEnd();
    if (onTransitionEnd) {
      onTransitionEnd(open);
    }
  };

  return (
    <div
      className={classNames}
      data-test-id={dataTestId}
      onTransitionEnd={handleTransitionEnd}
      ref={collapseContent}
      style={collapseStyles}
      {...restProps}
    >
      {children}
    </div>
  );
};

Collapse.displayName = 'Collapse';
