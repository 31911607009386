'use client';

import React, { useEffect, useState } from 'react';

import getCollapseStyles from '../Utils/GetCollapseStyles';

import { UseCollapse } from './UseCollapse.types';

function useCollapse(open: boolean, showDropShadow?: boolean): UseCollapse {
  const collapseContent = React.useRef<HTMLDivElement>(null);
  const [collapseStyles, setCollapseStyles] = useState<React.CSSProperties>({});
  const setExpandedStyle = () => {
    if (open)
      setCollapseStyles(styles => ({
        ...styles,
        height: 'auto',
        overflow: 'visible',
        boxShadow: showDropShadow ? '0px 2px 4px 0px rgba(38, 38, 38, 0.06)' : undefined,
      }));
  };
  const setCollapsedStyle = () =>
    setCollapseStyles(styles => ({
      ...styles,
      height: collapseContent.current?.scrollHeight + 'px',
    }));
  const handlerTransitionEnd = setExpandedStyle;

  useEffect(() => {
    const styles = getCollapseStyles(collapseContent, open);

    if (!open) setCollapsedStyle();

    const animationFrameId = window.requestAnimationFrame(() => {
      setCollapseStyles(styles);
      setExpandedStyle();
    });

    return () => window.cancelAnimationFrame(animationFrameId);
  }, [open]);

  // Only for the first initialization
  useEffect(() => {
    const animationFrameId = window.requestAnimationFrame(() =>
      setCollapseStyles(styles => ({
        ...styles,
        overflow: open ? 'visible' : 'hidden',
      })),
    );

    return () => window.cancelAnimationFrame(animationFrameId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    collapseContent,
    collapseStyles,
    handlerTransitionEnd,
  };
}

export default useCollapse;
