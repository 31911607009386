import { marketingSchema } from './MarketingSchema';
import { MarketingEvent } from './MarketingEvent';

export const marketingToEvent = ({
  marketingValues: { fbc, fbclid, gclid, msclkid, ttclid, ad_id, OutbrainClickId, ScCid },
}: {
  marketingValues: {
    fbc?: string;
    fbclid?: string;
    gclid?: string;
    msclkid?: string;
    ttclid?: string;
    ad_id?: string;
    OutbrainClickId?: string;
    ScCid?: string;
  };
}): MarketingEvent => {
  const event: MarketingEvent = {
    schema: marketingSchema.ref,
    data: {
      fbc,
      fbclid,
      gclid,
      msclkid,
      ttclid,
      ad_id,
      OutbrainClickId,
      ScCid,
    },
  };

  return event;
};
