import { Locale } from '../../Entities/Locale/Locale.entity';
import { mappedCurrencies, fallBackCurrency } from './MappedCurrencies';
import { mappedLocales } from './MappedLocales';

export const getFormattedCurrency = (
  value: number | undefined,
  locale: Locale,
  config?: Intl.NumberFormatOptions,
) => {
  const options: Intl.NumberFormatOptions = {
    style: 'currency',
    currency: mappedCurrencies[locale] || fallBackCurrency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    ...config,
  };
  return new Intl.NumberFormat(mappedLocales[locale], options).format(value ?? 0);
};
