import React from 'react';

import { CheckboxProps } from './Checkbox.types';

import styles from './Styles/Checkbox.module.css';

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  (
    { checked, onChange, disabled, error, dataTestId, className, errorMessage, ...otherProps },
    ref,
  ) => {
    return (
      <div
        className={`${styles.wrapper} ${error ? styles.error : ''} ${className ? className : ''}`}
      >
        <input
          data-test-id={dataTestId}
          ref={ref}
          checked={checked}
          className={styles.checkbox}
          disabled={disabled}
          onChange={onChange}
          type="checkbox"
          {...otherProps}
        />
        {error && (
          <div className={styles.errorMessageContainer}>
            <span className={styles.errorMessage}>{errorMessage}</span>
          </div>
        )}
      </div>
    );
  },
);

Checkbox.displayName = 'Checkbox';

export default Checkbox;
